var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "wuwow-card phone-width recommend-content",
      attrs: {
        id: "recommendTitlePosition",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "wuwow-card-head text-center",
        },
        [
          _c(
            "h4",
            {
              staticClass: "wuwow-card-title",
            },
            [
              _vm._v("「8小時英文邏輯速成班」推薦好友獎勵活動！(B)"),
              _c("font-awesome-icon", {
                directives: [
                  {
                    name: "b-modal",
                    rawName: "v-b-modal.recommend_info_modal",
                    modifiers: {
                      recommend_info_modal: true,
                    },
                  },
                ],
                staticClass: "icon-infor",
                attrs: {
                  icon: "info-circle",
                  size: "lg",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "wuwow-card-body text-center card-body",
        },
        [
          _c(
            "table",
            {
              staticClass: "table table-hover table-clsapc-record",
            },
            [
              _c(
                "thead",
                {
                  staticClass: "thead-default",
                },
                [
                  _c("tr", [
                    _c("th", [_vm._v(_vm._s(_vm.$t("pruduct_name")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("bonus_note")))]),
                    _c("th", [_vm._v(_vm._s(_vm.$t("discount_amount")))]),
                    _c("th"),
                  ]),
                ]
              ),
              _c(
                "tbody",
                _vm._l(_vm.FAKE_recommend_act, function (item) {
                  return _c(
                    "tr",
                    {
                      key: item.description,
                    },
                    [
                      _c("td", [_vm._v(_vm._s(item.description))]),
                      _c(
                        "td",
                        {
                          staticStyle: {
                            width: "10px",
                          },
                        },
                        [_vm._v(_vm._s(item.bonus_note))]
                      ),
                      _c(
                        "td",
                        [
                          _vm._v(_vm._s(item.discount_amount)),
                          item.discount_type == "percent"
                            ? [_c("span", [_vm._v("%")])]
                            : _vm._e(),
                        ],
                        2
                      ),
                      _c(
                        "td",
                        [
                          _c(
                            "b-button",
                            {
                              directives: [
                                {
                                  name: "b-modal",
                                  rawName: "v-b-modal.code_modal",
                                  modifiers: {
                                    code_modal: true,
                                  },
                                },
                              ],
                              staticClass: "btn-recommend-code",
                              attrs: {
                                variant: "outline-primary",
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("show_recommend_code")))]
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                0
              ),
            ]
          ),
          _c("div", [
            _c(
              "span",
              {
                staticClass: "text-danger warning-text",
              },
              [_vm._v(" " + _vm._s(_vm.$t("recommend_warning")))]
            ),
          ]),
        ]
      ),
      _c("recommend-info-modal"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }