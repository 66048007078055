var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    {
      staticClass: "wuwow-card phone-width self-content",
    },
    [
      _vm._m(0),
      _c(
        "div",
        {
          staticClass: "wuwow-card-body text-center card-body",
        },
        [
          _c("img", {
            staticStyle: {
              width: "100%",
            },
            attrs: {
              src: "http://cdn.wushare.tw/images/event/8hrs-english-logic-bootcamp-for-wuwow-vip.png",
            },
          }),
          _c(
            "div",
            {
              staticClass: "self-coupon",
            },
            [
              _c(
                "span",
                {
                  staticClass: "mr-2",
                },
                [_vm._v(_vm._s(_vm.$t("code")) + ":")]
              ),
              _c(
                "span",
                {
                  staticClass: "coupon-code-text px-2 py-1",
                },
                [_vm._v("10QO5BR0-J-27205-S(B)")]
              ),
              _c(
                "b-button",
                {
                  directives: [
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:copy",
                      value: "10QO5BR0-J-27205-S(B)",
                      expression: '"10QO5BR0-J-27205-S(B)"',
                      arg: "copy",
                    },
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:success",
                      value: _vm.onCopySelf,
                      expression: "onCopySelf",
                      arg: "success",
                    },
                  ],
                  attrs: {
                    variant: "outline-primary btn-sm ml-2",
                  },
                },
                [_vm._v(_vm._s(_vm.$t("copy")))]
              ),
            ],
            1
          ),
          _c("div", [
            _c(
              "span",
              {
                staticClass: "text-danger warning-text",
              },
              [_vm._v(" " + _vm._s(_vm.$t("self_purchase_warning")) + " 1")]
            ),
          ]),
          _c(
            "b-button",
            {
              staticClass: "mt-3",
              attrs: {
                id: "additionalPrchaseTitlePosition",
                variant: "primary",
              },
              on: {
                click: function ($event) {
                  return _vm.openWushare()
                },
              },
            },
            [_vm._v(_vm._s(_vm.$t("pruduct_link")))]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h

    return _c(
      "div",
      {
        staticClass: "wuwow-card-head text-center",
      },
      [
        _c(
          "h4",
          {
            staticClass: "wuwow-card-title",
          },
          [_vm._v("WUWOW學員專屬自購優惠(B)")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }