var render = function render() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "b-modal",
    {
      attrs: {
        id: "code_modal",
        centered: "",
        title: _vm.$t("my_code"),
        "header-bg-variant": "primary",
        "header-text-variant": "light",
        "hide-footer": "",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "text-center",
        },
        [
          _c(
            "span",
            {
              staticStyle: {
                "font-size": "28px",
              },
            },
            [_vm._v("  10QO3IGF-V-27205-A(B)  ")]
          ),
          _c("br"),
          _c(
            "b-button",
            {
              directives: [
                {
                  name: "clipboard",
                  rawName: "v-clipboard:copy",
                  value: "10QO3IGF-V-27205-A(B)",
                  expression: '"10QO3IGF-V-27205-A(B)"',
                  arg: "copy",
                },
                {
                  name: "clipboard",
                  rawName: "v-clipboard:success",
                  value: _vm.onCopy,
                  expression: "onCopy",
                  arg: "success",
                },
              ],
              staticClass: "copy-btn my-4",
              attrs: {
                variant: "primary",
              },
            },
            [_vm._v(_vm._s(_vm.$t("copy")))]
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }